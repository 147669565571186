import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_map from 'highcharts/modules/map'
import mapDataIN from './in.geo.json' // Load India map data
import axios from 'axios'
import { baseURL } from '../../config/baseURLPath'
// Initialize the map module
HC_map(Highcharts)

const IndiaMap = () => {
  const [chartData, setChartData] = useState([])
  // const geojsonData = feature(mapDataIN, 'states')
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'))
    axios({
      method: 'get',
      url: baseURL + 'sponsor-state-wise/' + userData.Sponsor.sponsor_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }).then((response) => {
      // console.log(response?.data?.data?.stateWise, 'State wise')
      let filteredData = response?.data?.data?.stateWise?.filter(
        (stateGroup) => stateGroup[0] !== null,
      )
      setChartData(filteredData)
    })
  }, [])

  const chartOptions = {
    chart: {
      map: mapDataIN, // Reference to the loaded map
    },
    title: {
      text: 'State-wise Student Distribution in India',
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, '#EFEFFF'],
        [0.5, '#4444FF'],
        [1, '#000022'],
      ],
    },
    series: [
      {
        // data: chartData, // Use the fetched data here
        mapData: mapDataIN,
        name: 'Number of Students',
        states: {
          hover: {
            color: '#BADA55',
          },
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
      },
      {
        type: 'mapbubble',
        name: 'Beneficiaries',
        joinBy: ['st_nm', 'id'],
        data: chartData,
        minSize: '2%',
        maxSize: '10%',
        tooltip: {
          pointFormat: '{point.properties.hc-a2}: {point.st_nm} - {point.z} Nos',
        },
      },
    ],
  }

  return (
    <div className="chart-container">
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        constructorType={'mapChart'}
      />
    </div>
  )
}

export default IndiaMap

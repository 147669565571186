import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import '../admin.css'

import ManageCourse from './ManageCourse'

const CourseItem = () => {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(1)

  return (
    <div className="container">
      <Row>
        <Col>
          <Button
            onClick={() => setActiveTab(1)}
            className={activeTab === 1 ? 'instituionTabSection active' : 'instituionTabSection'}
          >
            {location?.state?.course_id ? 'Update Course' : 'New Course'}
          </Button>
        </Col>
        <ManageCourse courseId={location?.state?.course_id} />
      </Row>
    </div>
  )
}

export default CourseItem

/* eslint-disable no-useless-computed-key */
import React, { useEffect } from 'react'
import { Card, Row, Col, Form, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import '../admin.css'

import useCourse from './useCourse'
import Select from 'react-select'
const ManageCourse = ({ courseId = undefined }) => {
  const {
    subjectList,
    subjectSkills,
    handleModuleReqParams,
    moduleReqParams,
    submitModule,
    moduleReqParamsError,
    getSubjects,
    getCourse,
  } = useCourse()

  const history = useHistory()

  useEffect(() => {
    getSubjects()
    if (courseId) {
      getCourse(courseId)
    }
  }, [])

  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{ padding: '0px 11px' }}>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Course URL</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_url"
                    placeholder=""
                    value={moduleReqParams.course_url}
                    onChange={handleModuleReqParams}
                    className={moduleReqParamsError?.course_url && 'border-red'}
                  />
                  {moduleReqParamsError?.course_url && (
                    <span className="color-red">{moduleReqParamsError?.course_url}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Course Name <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_name"
                    placeholder=""
                    value={moduleReqParams.course_name}
                    onChange={handleModuleReqParams}
                    className={moduleReqParamsError?.course_name && 'border-red'}
                  />
                  {moduleReqParamsError?.course_name && (
                    <span className="color-red">{moduleReqParamsError?.course_name}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Course Description<span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_desc"
                    placeholder=""
                    value={moduleReqParams.course_desc}
                    onChange={handleModuleReqParams}
                    className={moduleReqParamsError?.course_desc && 'border-red'}
                  />
                  {moduleReqParamsError?.course_desc && (
                    <span className="color-red">{moduleReqParamsError?.course_desc}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Course Subject <span style={{ color: '#ff0000' }}>*</span>{' '}
                </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select
                    name="subject_id"
                    value={moduleReqParams.subject_id}
                    onChange={handleModuleReqParams}
                    className={moduleReqParamsError?.subject_id && 'border-red'}
                  >
                    <option value="">Select Course Subject</option>
                    {subjectList.map((subject, index) => (
                      <option key={index} value={subject.subject_id}>
                        {subject.subject_name}
                      </option>
                    ))}
                  </Form.Select>
                  {moduleReqParamsError?.subject_id && (
                    <span className="color-red">{moduleReqParamsError?.subject_id}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Subject Skills <span style={{ color: '#ff0000' }}>*</span>{' '}
                </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Select
                    options={subjectSkills}
                    isMulti
                    value={moduleReqParams.sub_skill_id}
                    onChange={(val) => handleModuleReqParams('sub_skill_id', val)}
                    placeholder="Select Skills"
                    getOptionLabel={(e) => e.skill_name} // Custom label key
                    getOptionValue={(e) => e.skill_id} // Custom value key
                  />
                  {moduleReqParamsError?.sub_skill_id && (
                    <span className="color-red">{moduleReqParamsError?.sub_skill_id}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Course Difficulty Level</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select
                    name="course_difficulty_level"
                    value={moduleReqParams.course_difficulty_level}
                    onChange={handleModuleReqParams}
                  >
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Mixed">Mixed</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Course Collaboration</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_collaboration"
                    placeholder=""
                    value={moduleReqParams.course_collaboration}
                    onChange={handleModuleReqParams}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Course Duration <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    min={1}
                    name="duration"
                    placeholder="Enter Course Duration"
                    value={moduleReqParams.duration}
                    onChange={handleModuleReqParams}
                    className={moduleReqParamsError?.duration && 'border-red'}
                  />
                  {moduleReqParamsError?.duration && (
                    <span className="color-red">{moduleReqParamsError?.duration}</span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className="mb-3">
                  <Form.Select
                    name="duration_unit"
                    value={moduleReqParams.duration_unit}
                    onChange={handleModuleReqParams}
                  >
                    <option value="Hours">Hours</option>
                    <option value="Days">Days</option>
                    <option value="Months">Months</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Course Rating</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select
                    name="course_rating"
                    value={moduleReqParams.course_rating}
                    onChange={handleModuleReqParams}
                  >
                    <option value="">N/A</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Course Cost (INR) <span style={{ color: '#ff0000' }}>*</span>{' '}
                </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="fees"
                    placeholder=""
                    value={moduleReqParams.fees}
                    onChange={handleModuleReqParams}
                    className={moduleReqParamsError?.fees && 'border-red'}
                  />
                  {moduleReqParamsError?.fees && (
                    <span className="color-red">{moduleReqParamsError?.fees}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Set Up Cost</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="set_up_cost"
                    placeholder=""
                    value={moduleReqParams.set_up_cost}
                    onChange={handleModuleReqParams}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Minimum qualification for the Course</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="minimum_qualification"
                    placeholder=""
                    value={moduleReqParams.minimum_qualification}
                    onChange={handleModuleReqParams}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Batch Size</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="batch_size"
                    placeholder=""
                    value={moduleReqParams.batch_size}
                    onChange={handleModuleReqParams}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Course Learning Product Type </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_learning_product_type"
                    placeholder=""
                    value={moduleReqParams.course_learning_product_type}
                    onChange={handleModuleReqParams}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Course Instructor</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_instructors"
                    placeholder=""
                    value={moduleReqParams.course_instructors}
                    onChange={handleModuleReqParams}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col>
            <Button
              className="btn primaryAdminButton"
              role="button"
              onClick={() => history.goBack()}
              style={{ marginTop: '15px' }}
            >
              Back
            </Button>
          </Col>
          <Col>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
              <Button onClick={submitModule} className="primaryAdminButton">
                {courseId ? 'Update Course' : 'Create Course'}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ManageCourse

import { useState } from 'react'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
const BLANK_MODULE_REQUEST = {
  //   custom_course_id: '',
  course_url: '',
  course_name: '',
  course_desc: '',
  subject_id: '',
  sub_skill_id: [],
  course_difficulty_level: 'Beginner',
  course_collaboration: '',
  duration: '',
  duration_unit: 'Days',
  course_rating: '',
  fees: '',
  set_up_cost: '',
  minimum_qualification: '',
  batch_size: '',
  course_learning_product_type: '',
  course_instructors: '',
  institution_id: JSON.parse(localStorage.getItem('user'))?.VendorData?.institution_id,
  iud_flag: 'I',
}

const useCourse = () => {
  const history = useHistory()
  const [moduleReqParams, setModuleReqParams] = useState(BLANK_MODULE_REQUEST)
  const [moduleReqParamsError, setModuleReqParamsError] = useState(null)

  const [subjectList, setSubjectList] = useState([])
  const [subjectSkills, setSubjectSkills] = useState([])
  const [preSelectedSkills, setPreSelectedSkills] = useState([])
  const resetModuleRequestParams = () => setModuleReqParams(BLANK_MODULE_REQUEST)

  const handleModuleReqParams = (e, options = []) => {
    if (e === 'sub_skill_id') {
      setModuleReqParams({ ...moduleReqParams, sub_skill_id: options })
      return
    }
    const { name, value } = e.target
    if (moduleReqParamsError !== null) {
      setModuleReqParamsError(null)
    }
    if (name === 'subject_id') {
      getSubjectSkills(value)
      setModuleReqParams({ ...moduleReqParams, [name]: value, sub_skill_id: [] })
      return
    }

    setModuleReqParams({ ...moduleReqParams, [name]: value })
  }

  const getSubjects = () => {
    axios({
      method: 'get',
      url: baseURL + 'getSubjects',
    })
      .then((response) => {
        setSubjectList(response.data.Content)
      })
      .catch(() => {})
  }

  const getSubjectSkills = (subject_id) => {
    axios({
      method: 'get',
      url: baseURL + 'getSubjectSkills/' + subject_id,
    })
      .then((response) => {
        setSubjectSkills(response.data.data)
      })
      .catch(() => {})
  }

  const submitModule = () => {
    if (moduleReqParams?.course_name?.trim() === '') {
      setModuleReqParamsError({ course_name: 'Please enter course name' })
      return
    }
    if (moduleReqParams?.course_desc?.trim() === '') {
      setModuleReqParamsError({ course_desc: 'Please enter course description' })
      return
    }
    if (moduleReqParams?.subject_id === '') {
      setModuleReqParamsError({ subject_id: 'Please select subject' })
      return
    }
    if (moduleReqParams?.sub_skill_id?.length === 0) {
      setModuleReqParamsError({ sub_skill_id: 'Please subject skills' })
      return
    }
    if (moduleReqParams?.duration === '') {
      setModuleReqParamsError({ duration: 'Please enter duration' })
      return
    }
    if (moduleReqParams?.fees === '') {
      setModuleReqParamsError({ fees: 'Please enter course cost' })
      return
    }
    let requestParams = { ...moduleReqParams }
    if (moduleReqParams?.iud_flag === 'U') {
      // Prepare a Set of preSkillIds for faster lookup
      const preSkillIds = new Set(preSelectedSkills?.map((preSkill) => preSkill.skill_id))
      const currentSkillIds = new Set(moduleReqParams?.sub_skill_id.map((skill) => skill.skill_id))

      // Identify new skills (in currentSkills but not in preSkills)
      const newSkills = moduleReqParams?.sub_skill_id.filter(
        (currentSkill) => !preSkillIds.has(currentSkill.skill_id),
      )

      // Identify skills to be deleted (in preSkills but not in currentSkills)
      const needToDeleted = preSelectedSkills?.filter(
        (item1) => !currentSkillIds.has(item1.skill_id),
      )

      // Combine the new skills and the deleted skills
      const combinedSkills = [...newSkills, ...needToDeleted]

      // Update the requestParams with the combined skills
      requestParams = { ...moduleReqParams, sub_skill_id: combinedSkills }
    }

    requestParams = {
      ...requestParams,
      institution_id: JSON.parse(localStorage.getItem('user'))?.VendorData?.institution_id,
    }

    axios({
      method: 'POST',
      url: baseURL + 'setCourse',
      data: requestParams,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then(() => {
        new swal({
          title: 'Done!',
          text: 'New Course created successfully',
          icon: 'success',
          timer: 2000,
          button: false,
        }).then(() => {
          history.replace('/service-partner/course-list')
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCourse = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getCourseById/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setModuleReqParams({ ...response.data.Content, iud_flag: 'U' })
        if (response.data.Content.subject_id) {
          getSubjectSkills(response.data.Content.subject_id)
          setPreSelectedSkills(response.data.Content.sub_skill_id)
        }
      })
      .catch(() => {})
  }

  return {
    moduleReqParams,
    setModuleReqParams,
    handleModuleReqParams,
    moduleReqParamsError,
    resetModuleRequestParams,
    subjectList,
    getSubjects,
    subjectSkills,
    submitModule,
    getCourse,
  }
}

export default useCourse

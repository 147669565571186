/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { baseURL } from '../../../config/baseURLPath'

import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
// import DataTable from 'react-data-table-component'
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap'
import CustomInput from '../../utils/CustomInput'
import '../admin.css'
import swal from 'sweetalert2'
import { read, utils } from 'xlsx'
import Papa from 'papaparse'

import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
//import "primeflex/primeflex.css"
import Swal from 'sweetalert2'

const List = (props) => {
  const imageUploader = useRef(null)
  const history = useHistory()
  const [courses, setCourses] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [password, setPassword] = useState('')
  const [activeAdmin, setActiveAdmin] = useState('')
  const [subjectList, setSubjectList] = useState([])
  const [skillList, setSkillList] = useState([])
  const [activeId, setActiveId] = useState(props.id)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [institution, setInstitution] = useState(0)
  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [csvData, setCsvData] = useState([])

  const handleClose = () => {
    setConfirmationModal(false)
  }

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  const userData = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    try {
      fetchRoles()
      getSubjects()
      fetchSkills()
      // getCourses(userData?.VendorData?.institution_id)
      setInstitution(userData?.VendorData?.institution_id)
    } catch (e) {}
  }, [])

  const getSubjects = () => {
    axios({
      method: 'get',
      url: baseURL + 'getSubjects',
    })
      .then((response) => {
        setSubjectList(response.data.Content)
      })
      .catch(() => {})
  }

  const fetchSkills = () => {
    axios({
      method: 'get',
      url: baseURL + 'getSkills',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }).then((response) => {
      setSkillList(response.data.data)
    })
  }

  const getCourses = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getCoursesByVendor/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setCourses(response.data.Content)
        setLoading(false)
      })
      .catch(() => {})
  }

  const validateTemplate = (data) => {
    // Define the expected headers from the template
    const expectedHeaders = [
      'course_url',
      'course_name',
      'course_description',
      'course_subject',
      'subject_skills',
      'course_difficulty_level',
      'course_collaboration',
      'course_duration',
      'course_rating',
      'course_cost(inr)',
      'set_up_cost',
      'minimum_qualification_for_the_course',
      'batch_size',
      'course_learning_product_type',
      'course_instructor',
    ]

    // Get the headers from the uploaded file
    const fileHeaders = data[0].map((header) => header.toLowerCase())

    // Check if all expected headers are present in the file
    const isValidTemplate = expectedHeaders.every((header) => fileHeaders.includes(header))

    return isValidTemplate
  }

  const deleteCourse = (id) => {
    setActiveId(id)
    setConfirmationModal(true)
  }

  const deleteRecord = () => {
    setConfirmationModal(false)
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }
    axios
      .post(
        baseURL + 'deleteCourse/' + activeId,
        {
          // admin_id: activeId,
          // institution_id: props.id
        },
        jwtHeader,
      )
      .then(() => {
        getCourses(institution)
        new swal({
          title: 'Done!',
          text: 'Course removed successfully',
          icon: 'success',
          timer: 2000,
          button: false,
        }).then(() => {})
      })
      .catch(() => {
        // alert('Something went wrong. Please try again.')
      })
  }

  const editCourse = (course_id) => {
    history.push('/service-partner/course-edit', { course_id: course_id })
  }

  const addCourse = () => {
    history.push('/service-partner/course-add')
  }

  const successAction = () => {
    if (password.trim() === '') {
      return
    }
    const values = {
      admin_email: activeAdmin,
      password: password.trim(),
    }
    axios
      .post(baseURL + 'auth/updateAdminPass', values, jwtHeader)
      .then(() => {
        setShowModal(false)
        alert('Password updated successfully')
      })
      .catch((err) => console.log(err))
  }

  const [values, setValues] = useState({
    institution_id: userData?.VendorData?.institution_id,
    course_name: '',
    subject_id: '',
    custom_course_id: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const [roles, setRoles] = useState([])
  const fetchRoles = () => {
    axios
      .get(baseURL + 'getSubjects')
      .then((response) => {
        // console.log(response);
        setRoles(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSearch = () => {
    axios
      .post(baseURL + 'searchCourses', values, jwtHeader)
      .then((res) => {
        setCourses(res.data.Content)
      })
      .catch((err) => {
        Swal.fire('Error', err?.response?.data?.Message, 'error')
        setCourses([])
      })
  }

  const handleImageUpload = async (e /*:File*/) => {
    const file = e.target.files[0]
    /* Boilerplate to set up FileReader */
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result
      const wb = read(bstr, { type: rABS ? 'binary' : 'array' })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      let data = utils.sheet_to_json(ws, { header: 1 })

      data = data.filter((row) =>
        row.some((cell) => cell !== undefined && cell !== null && cell !== ''),
      )

      /* Update state */
      if (data.length === 1) {
        alert('The uploaded Excel file is empty. Please upload a non-empty file.')
        return // Exit the function
      }

      const incorrect_list = []
      const subjectColumnIndex = 3
      const skillsColumnIndex = 4

      // Validate the template before processing
      if (validateTemplate(data)) {
        let mappedData = []
        data.forEach((row) => {
          if (row[1] === '' || row[2] === '' || row[7] === '') {
            incorrect_list.push(row)
            return null
          }

          const subjectValue = row[subjectColumnIndex]
          const skillValue = row[skillsColumnIndex]
          const mappedSubject = subjectList.find(
            (sub) =>
              sub.subject_name?.trim()?.toLowerCase() === subjectValue?.trim()?.toLowerCase(),
          )

          let skillNameArray = skillValue?.split(',')
          let skillIdArray = []
          skillNameArray?.forEach((skillName) => {
            const mappedSkill = skillList.find(
              (sub) => sub.skill_name?.trim()?.toLowerCase() === skillName?.trim()?.toLowerCase(),
            )
            skillIdArray?.push(mappedSkill)
          })

          if (!mappedSubject || skillIdArray?.length === 0) {
            incorrect_list.push(row)
            return null
          } else {
            row[subjectColumnIndex] = mappedSubject.subject_id
            row[skillsColumnIndex] = skillIdArray
            mappedData?.push(row)
          }
        })
        setCsvData(incorrect_list)
        // downloadCsv();
        uploadCourses(mappedData, incorrect_list)
      } else {
        // If the template is not valid, show an error message
        alert('Invalid template. Please use the correct template.')
      }
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)

    e.target.value = null
  }

  const downloadCsv = (errorData) => {
    const csvContent = Papa.unparse(errorData)
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', 'incorrect_data.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const durationEnumValue = (val) => {
    if (/hour/i.test(val)) {
      return 'Hours'
    } else if (/day/i.test(val)) {
      return 'Days'
    }
    return 'Months'
  }

  const uploadCourses = (data, errorData) => {
    let records = []
    if (data.length < 1) {
      Swal.fire({
        title: 'Validation Error!',
        text: 'No valid data found in uploaded excel. Please check if subjects and skills are mapped correctly.',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: errorData?.length > 0 ? 'Download Incorrect Records!' : 'Okay',
        cancelButtonText: 'Close',
      }).then((result) => {
        if (result.isConfirmed) {
          // Call the download function here
          if (errorData?.length > 0) {
            downloadCsv(errorData)
          }
        } else {
          // User clicked Cancel or closed the modal
          getCourses(institution)
        }
      })
      return
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i] != null) {
        let durationValue = data[i][7] !== undefined ? data[i][7] + '' : ''
        let durationArray = durationValue ? durationValue.split(' ') : []

        let ele = {
          course_url: data[i][0] !== undefined ? data[i][0] : '',
          course_name: data[i][1] !== undefined ? data[i][1] : '',
          course_desc: data[i][2] !== undefined ? data[i][2] : '',
          subject_id: data[i][3] !== undefined ? data[i][3] : '',
          sub_skill_id: data[i][4] !== undefined ? data[i][4] : '',
          course_difficulty_level: data[i][5] !== undefined ? data[i][5] : '',
          course_collaboration: data[i][6] !== undefined ? data[i][6] : '',
          duration: durationArray.length > 0 ? Number(durationArray[0]) : '',
          duration_unit: durationArray.length > 1 ? durationEnumValue(durationArray[1]) : '',
          course_rating: data[i][8] !== undefined ? data[i][8] : '',
          fees: data[i][9] !== undefined ? data[i][9] : '',
          set_up_cost: data[i][10] !== undefined ? data[i][10] : '',
          minimum_qualification: data[i][11] !== undefined ? data[i][11] : '',
          batch_size: data[i][12] !== undefined ? data[i][12] : '',
          course_learning_product_type: data[i][13] !== undefined ? data[i][13] : '',
          course_instructors: data[i][14] !== undefined ? data[i][14] : '',
          institution_id: institution,
        }

        records.push(ele)
      }
    }

    axios({
      method: 'POST',
      url: baseURL + 'addCoursesBulk',
      data: records,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        Swal.fire({
          title: 'Done!',
          text: 'New records added successfully',
          icon: 'success',
          showCancelButton: csvData?.length > 0,
          confirmButtonText: csvData?.length > 0 ? 'Download Incorrect Records!' : 'Okay',
          cancelButtonText: 'Close',
        }).then((result) => {
          if (result.isConfirmed) {
            // Call the download function here
            if (csvData?.length > 0) {
              downloadCsv(errorData)
            }
          } else {
            // User clicked Cancel or closed the modal
            getCourses(institution)
          }
        })
      })
      .catch((error) => {
        swal.fire('Check files fields!', error?.response?.data?.Error?.Message, 'error')
        // alert('Something went wrong. Please try again.')
      })
  }

  const formatCurrency = (value) => {
    return `₹${value}`
  }

  const priceBodyTemplate = (product) => {
    return formatCurrency(product.fees)
  }

  return (
    <div className="container" style={{ marginTop: '10px' }}>
      <Card>
        <Card.Header as="h4" style={{ color: '#000', backgroundColor: '#ffffff' }}>
          <div style={{ float: 'right', marginBottom: '10px' }}>
            <Button className="btn primaryAdminButton" role="button" onClick={() => addCourse()}>
              Add Courses
            </Button>
            <Button
              className="btn primaryAdminButton"
              role="button"
              onClick={() => imageUploader.current.click()}
              style={{ marginLeft: '5px' }}
            >
              Import Courses
            </Button>
            <input
              className="edit_btn_photo"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: 'none',
              }}
            />
            <a
              href="/Course_template.xlsx"
              style={{ fontSize: '14px', float: 'right', marginTop: '10px', marginLeft: '10px' }}
            >
              Download Template
            </a>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Select name="subject_id" value={values.subject_id} onChange={handleChange}>
                  <option value="">Subject</option>
                  {roles?.map((role) => (
                    <option value={role.subject_id} key={role.subject_id}>
                      {role.subject_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {/* <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="vendor_id"
                  placeholder="Enter Vendor Id"
                  value={values.vendor_id}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col> */}
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="custom_course_id"
                  placeholder="Enter Course ID"
                  value={values.custom_course_id}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="course_name"
                  placeholder="Enter Course Name"
                  value={values.course_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            {/* <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="tel"
                  name="mobile_number"
                  placeholder="Enter Mobile"
                  value={values.mobile_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col> */}

            <Col md={4}>
              <Button onClick={handleSearch} className="primaryAdminButton">
                Search
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {courses.length > 0 && (
        <DataTable
          // editMode="row"
          // onRowEditComplete={onRowEditComplete}
          stripedRows
          showGridlines={true}
          value={courses}
          paginator
          rows={5}
          dataKey="id"
          filterDisplay="row"
          globalFilterFields={[]}
          tableStyle={{ minWidth: '50rem' }}
          scrollable
          // scrollHeight="400px"
        >
          <Column
            // editor={(options) => textEditor(options)}
            field="custom_course_id"
            header="Course ID"
            filterPlaceholder="Search by Course ID"
            emptyMessage="No courses found!"
          />
          <Column
            // editor={(options) => textEditor(options)}
            field="course_name"
            header="Course Name"
            filterPlaceholder="Search by Course name"
            emptyMessage="No courses found!"
          />
          <Column
            // editor={(options) => textEditor(options)}
            field="subject_name"
            header="Subject Name"
            // filterPlaceholder="Search by Course Category"
            // emptyMessage="No Category found!"
          />

          {/* <Column
            field="course_desc"
            header="Course Description"
            style={{ maxHeight: '200px' }}
            filterPlaceholder="Search by Course Description"
            emptyMessage="No Course Description found!"
          /> */}

          <Column
            // editor={(options) => textEditor(options)}
            field="fees"
            header="Fees"
            sortable
            emptyMessage="No Course fees found!"
            body={priceBodyTemplate}
          />

          <Column
            // editor={(options) => textEditor(options)}
            field="course_difficulty_level"
            header="Course Difficulty Level"
            filterPlaceholder="Search by Course Description"
            emptyMessage="No Course Description found!"
          />

          <Column
            // editor={(options) => textEditor(options)}
            field="duration"
            header="Course Duration"
            sortable
            filterPlaceholder="Search by Course Description"
            emptyMessage="No Course Description found!"
          />
          <Column
            // editor={(options) => textEditor(options)}
            field="duration_unit"
            header=""
          />
          <Column
            header="Actions"
            body={(rowData) => (
              <Button
                icon="pi pi-pencil"
                className="p-button-rounded p-button-secondary"
                onClick={() => editCourse(rowData?.course_id)}
              >
                {' '}
                <i className="fa fa-edit"></i>
              </Button>
            )}
          />
        </DataTable>
      )}

      {/* {cols.length === 0 ? 
      <DataTable columns={tableHeader} data={courses} pagination /> :
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {cols.map(c => (
                <th key={c.key}>{c.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((r, i) => (
              <tr key={i}>
                {cols.map(c => (
                  <td key={c.key}>{r[c.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div> } */}
      <Modal size="sm" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <Modal.Title>
            <Row>
              <Col>
                <p className="modalHeading">Change Password</p>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <CustomInput
              type="text"
              placeholder="New Password"
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <Button
            className="popupButtonFalse"
            onClick={(e) => {
              e.preventDefault()
              handleClose()
            }}
          >
            CANCEL
          </Button>
          <Button
            className="popupButtonTrue"
            onClick={(e) => {
              e.preventDefault()
              successAction()
            }}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={confirmationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to remove this record?</Modal.Body>
        <Modal.Footer>
          <button
            style={{ marginLeft: '5px', color: '#ffffff' }}
            className="btn btn-sm primaryAdminButton"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            style={{ marginLeft: '5px', color: '#ffffff' }}
            className="btn btn-sm primaryAdminButton"
            variant="primary"
            onClick={() => deleteRecord()}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default List
